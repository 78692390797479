import React, { useEffect, useRef, useState } from "react";
import { ReactFC } from "../../scripts/types";
import WithDrawer from "../../layouts/withDrawer";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  Button,
  TextField,
  Stack,
  Chip,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import PackageModal from "../../modals/nft/package";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CategoryIcon from "@mui/icons-material/Category";
import CategoryModal from "../../modals/nft/category";
import EditNftModal from "../../modals/nft/edit";
import Filter from "../../components/filter";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import "./nfts.css";

type MetaData = {
  description: string;
  image: string;
  title: string;
};

type NFT = {
  id: string;
  _id: string;
  category: Category[];
  comments: number;
  date: string;
  favorited: boolean;
  favorites: number;
  liked: boolean;
  likes: number;
  package: string;
  packageItems: string[];
  sale: number;
  tokenId: string;
  metadata: MetaData;
};

type Category = { _id: string; name: string };

const NftsPage: ReactFC<{}> = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<NFT[]>([]);
  const [tokenId, setTokenId] = useState("");
  const [category, setCategory] = useState<Category | null>();
  const [date, setDate] = useState<string | null>(null);
  const packageModal = useRef<any>();
  const categoryModal = useRef<any>();
  const editNftModal = useRef<any>();
  const [sortCategory, setSortCategory] = useState("");

  const columns: GridColDef[] = [
    { field: "tokenId", headerName: "TokenId", flex: 30, sortable: false },
    { field: "date", headerName: "Date", flex: 10, sortable: false },
    { field: "sale", headerName: "Price", sortable: false },
    { field: "favorites", headerName: "Favorites", flex: 10, sortable: false },
    { field: "likes", headerName: "Likes", flex: 10, sortable: false },
    { field: "comments", headerName: "Comments", flex: 10, sortable: false },
    {
      field: "categories",
      headerName: "Categories",
      flex: 30,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          categoryModal.current.openModal(params.id);
        };

        console.log(params.row.category.length);

        return (
          <Stack direction="row" spacing={1}>
            {params.row?.category?.map(({ name }: { name: string }) => (
              <Chip label={name} />
            ))}
          </Stack>
        );
      },
    },
    { field: "package", headerName: "Package", flex: 50, sortable: false },
    // {
    //   field: "package",
    //   headerName: "action",
    //   flex: 50,
    //   align: "center",
    //   renderCell: (params) => {
    //     const onClick = (e: any) => {
    //       packageModal.current.openModal(params.id);
    //     };

    //     return <Button onClick={onClick}>Package</Button>;
    //   },
    // },
    {
      field: "edit",
      headerName: "Edit",
      flex: 5,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const onClick = (e: any) => {
          editNftModal.current.openModal(params.row);
        };

        return (
          <IconButton disabled={params.row.owner} onClick={onClick}>
            <EditIcon color={params.row.owner ? "disabled" : "primary"} />
          </IconButton>
        );
      },
    },
  ];

  const componentDidMount = async () => {
    getNFTs(page, size);
    getCategories(1, 25);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const getCategories = async (page = 1, size = 25, q?: string) => {
    setLoadingCategories(true);
    const params: {
      pageNumber: number;
      pageSize: number;
      q?: string;
    } = {
      pageSize: size,
      pageNumber: page,
    };

    if (q) params.q = q;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.categories,
      params,
    }).finally(() => setLoadingCategories(false));

    if (!res) return;

    const { data } = res;

    setCategories(data);
  };

  const getNFTs = async (page = 1, size = 25) => {
    setLoading(true);

    const params: {
      pageSize: number;
      pageNumber: number;
      tokenId?: string;
      category?: string;
      date?: string;
      sort?: string;
    } = {
      pageSize: size,
      pageNumber: page,
    };

    if (tokenId) params.tokenId = tokenId;
    if (category) params.category = category._id;
    if (date) params.date = date;
    if (sortCategory) params.sort = sortCategory;

    const res = await ajax({
      method: "GET",
      params,
      hasToken: true,
      snackbar: enqueueSnackbar,
      url: urls.nfts,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        date: moment(item.date).format("YYYY/MM/DD"),
        sale: item.sale.price,
        package: item.package && item.package.name,
      }))
    );

    setTotal(metaData?.pagination?.totalRecords);
  };

  const onPageChange = (page = 10) => {
    setPage(page);

    getNFTs(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);

    getNFTs(page, size);
  };

  return (
    <WithDrawer title="NFTs" activeIndex={0}>
      <div className="nftPage">
        <Filter onFilter={() => getNFTs()}>
          <TextField
            label="Token Id"
            onChange={(e: any) => setTokenId(e.target.value)}
            className="space"
          />
          <Autocomplete
            className="space"
            id="asynchronous-demo"
            sx={{ width: 250 }}
            onInputChange={(event, newInputValue) => {
              getCategories(1, 25, newInputValue);
            }}
            value={category}
            onChange={(e: any, newValue: Category | null) => {
              setCategory(newValue);
            }}
            getOptionLabel={(option: Category) => option.name}
            options={categories}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Categories"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingCategories ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <LocalizationProvider className="space" dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Basic example"
              value={date}
              onChange={(newValue) => {
                if (newValue && moment(newValue).isValid())
                  setDate(
                    new Date(
                      new Date(newValue).setUTCHours(0, 0, 0, 0)
                    ).toISOString()
                  );
                else setDate(null);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormControl className="space" sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Sort Category</InputLabel>
            <Select
              value={sortCategory}
              label="Sort Name"
              onChange={(e: SelectChangeEvent) =>
                setSortCategory(e.target.value)
              }
            >
              <MenuItem value={""}>none</MenuItem>
              <MenuItem value={"+category"}>Ascending</MenuItem>
              <MenuItem value={"-categoy"}>Descending</MenuItem>
            </Select>
          </FormControl>
        </Filter>
        <DataGrid
          rows={rows}
          disableColumnFilter
          columns={columns}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          rowsPerPageOptions={[25, 50, 100]}
          page={page}
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
          className="dataGrid"
        />
      </div>
      <PackageModal ref={packageModal} enqueueSnackbar={enqueueSnackbar} />
      <CategoryModal ref={categoryModal} enqueueSnackbar={enqueueSnackbar} />
      <EditNftModal
        categories={categories}
        ref={editNftModal}
        enqueueSnackbar={enqueueSnackbar}
      />
    </WithDrawer>
  );
};

export default NftsPage;
