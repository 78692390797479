import React, { useState } from "react";
import { ReactFC } from "../../scripts/types";
import NoDrawer from "../../layouts/noDrawer";
import Box from "@mui/material/Box";
import { Navigate } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const LoginPage: ReactFC<{}> = () => {
  const [navigate, setNavigate] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const login = async () => {
    setLoading(true);

    const res = await ajax({
      url: urls.login,
      method: "POST",
      data: { username, password },
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    if (data?.token) {
      localStorage.setItem("token", data.token);
      setNavigate("/nfts");
    }
  };

  if (navigate) return <Navigate to={navigate} />;

  return (
    <NoDrawer>
      <Container maxWidth="sm" className="loginPage">
        <Box>
          <h2>LOGIN</h2>
          <div className="loginPage__fieldContainer">
            <TextField
              label="Username"
              className="TextField"
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
            />
            <TextField
              inputProps={{ color: "white" }}
              label="Password"
              className="TextField"
              value={password}
              type="password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <LoadingButton
              onClick={login}
              variant="contained"
              className="TextField Button"
              loading={loading}
            >
              Login
            </LoadingButton>
          </div>
        </Box>
      </Container>
    </NoDrawer>
  );
};

export default LoginPage;
