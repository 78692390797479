import * as React from "react";
import { ReactFC } from "../../../scripts/types";
import BaseModal from "../../base";
import TextField from "@mui/material/TextField";
import "./add.css";
import { Button } from "@mui/material";
import { ajax, urls } from "../../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

class AddFAQModal extends React.Component<
  {
    handleData?: (data: any) => void;
    enqueueSnackbar?: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  { open: boolean; question: string; answer: string }
> {
  state = {
    open: false,
    question: "",
    answer: "",
  };

  openModal = () => this.setState({ open: true });
  closeModal = () => this.setState({ open: false });

  submitFAQ = async () => {
    await ajax({
      method: "POST",
      hasToken: true,
      data: {
        question: this.state.question,
        answer: this.state.answer,
      },
      url: urls.FAQs,
      snackbar: this.props.enqueueSnackbar,
    });
  };

  addFAQ = async () => {
    await this.submitFAQ();

    this.setState({ answer: "", question: "" });

    this.closeModal();
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="addFaqContant">
          <TextField
            label="Question"
            className="TextField"
            value={this.state.question}
            onChange={(e: any) => this.setState({ question: e.target.value })}
          />
          <TextField
            label="Answer"
            multiline
            rows={5}
            className="TextField"
            value={this.state.answer}
            onChange={(e: any) => this.setState({ answer: e.target.value })}
          />
          <Button variant="contained" className="Button" onClick={this.addFAQ}>
            Add
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default AddFAQModal;
