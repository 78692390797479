import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";

export interface DialogTitleProps {
  id?: string;
  titleChildren?: React.ReactNode;
  children?: React.ReactNode;
  actionChildren?: React.ReactNode;
  onClose?: () => void;
  handleClose?: () => void;
  open: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "#2a2e39",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BaseModal = (props: DialogTitleProps) => {
  const {
    titleChildren,
    onClose,
    handleClose,
    open,
    actionChildren,
    children,
    ...other
  } = props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {titleChildren}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actionChildren}</DialogActions>
    </BootstrapDialog>
  );
};

export default BaseModal;
