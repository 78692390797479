import * as React from "react";
import BaseModal from "../../base";
import "./package.css";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { ajax, getImageUrl, urls } from "../../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

type Package = {
  _id: string;
  createdAt: string;
  description: string;
  image: string;
  name: string;
};

class PackageModal extends React.Component<
  {
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  {
    open: boolean;
    id: string;
    name: string;
    description: string;
    data: any;
    loading: boolean;
    mode: string;
    autoCompleteOpen: boolean;
    options: Package[];
    selectedOption: Package | null;
  }
> {
  state = {
    open: false,
    id: "",
    name: "",
    description: "",
    data: { name: "" },
    loading: false,
    mode: "add",
    autoCompleteOpen: false,
    options: [],
    selectedOption: null,
  };

  fillData = (data: any) =>
    this.setState({
      name: data?.name,
      description: data?.description,
    });

  openModal = (id = "", mode: "add" | "edit", data?: any) => {
    this.setState({ open: true, id, mode, data });

    if (mode === "edit") this.fillData(data);

    this.getPackages();
  };

  getPackages = async (page = 1, size = 100) => {
    this.setState({ loading: true });

    const res = await ajax({
      method: "GET",
      url: urls.packages,
      params: { pageNumber: page, pageSize: size },
      hasToken: true,
      snackbar: this.props.enqueueSnackbar,
    }).finally(() => this.setState({ loading: false }));

    if (!res) return;

    const { data, metaData } = res;

    this.setState({ options: data });
  };

  closeModal = () =>
    this.setState({ open: false, name: "", description: "", options: [] });

  submit = async () => {
    if (!this.state.selectedOption)
      return this.props.enqueueSnackbar("please select package", {
        variant: "error",
      });

    const selectedPackage: Package = this.state.selectedOption;

    const body = {
      package: selectedPackage._id,
    };

    const res = await ajax({
      method: "POST",
      url: urls.nftPackage(this.state.id),
      snackbar: this.props.enqueueSnackbar,
      hasToken: true,
      data: body,
    });

    if (!res) return;

    const { data } = res;

    console.log(data);

    this.closeModal();
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="addPackageModal">
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: 300 }}
            open={this.state.autoCompleteOpen}
            onOpen={() => this.setState({ autoCompleteOpen: true })}
            onClose={() => this.setState({ autoCompleteOpen: false })}
            onChange={(
              e: React.SyntheticEvent<Element, Event>,
              value: Package | null
            ) => this.setState({ selectedOption: value })}
            isOptionEqualToValue={(option: Package, value: Package) =>
              option._id === value._id
            }
            getOptionLabel={(option: Package) => option.name}
            options={this.state.options}
            loading={this.state.loading}
            renderInput={(params) => (
              <TextField
                {...params}
                className="TextField"
                label="packages"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Button
            disabled={this.state.loading}
            variant="contained"
            className="Button"
            onClick={this.submit}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default PackageModal;
