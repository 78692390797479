import React, { useEffect, useState } from "react";
import WithDrawer from "../../layouts/withDrawer";
import { ReactFC } from "../../scripts/types";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import "./transfer.css";
import Filter from "../../components/filter";
import { TextField } from "@mui/material";

type Transfer = {
  id: string;
  blockNumber: number;
  createdAt: string;
  wallet: string;
  name: string;
  tokenId: number;
  transactionHash: string;
};

const TransferPage: ReactFC = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<Transfer[]>([]);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState("");
  const [tokenId, setTokenId] = useState("");

  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    getTransfers(page, size);
  };

  const getTransfers = async (page = 1, size = 25) => {
    setLoading(true);

    const params: {
      pageNumber: number;
      pageSize: number;
      wallet?: string;
      tokenId?: string;
    } = {
      pageNumber: page,
      pageSize: size,
    };

    if (wallet) params.wallet = wallet;
    if (tokenId) params.tokenId = tokenId;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.transfers,
      params,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        wallet: item.to?.wallet,
        name: item.to?.name,
        createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
      }))
    );

    setTotal(metaData?.pagination?.totalRecords ?? 0);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    getTransfers(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);
    getTransfers(page, size);
  };

  const columns: GridColDef[] = [
    {
      field: "blockNumber",
      headerName: "Block number",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "wallet",
      headerName: "Wallet",
      width: 50,
      flex: 50,
      sortable: false,
    },
    { field: "name", headerName: "Name", width: 50, flex: 50, sortable: false },
    {
      field: "tokenId",
      headerName: "Token Id",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "transactionHash",
      headerName: "TransactionHash",
      width: 50,
      flex: 50,
      sortable: false,
    },
  ];

  return (
    <WithDrawer title="Transfers" activeIndex={3}>
      <div className="transferPage">
        <Filter onFilter={() => getTransfers(1, 25)}>
          <TextField
            label="Wallet"
            className="space"
            onChange={(e: any) => setWallet(`string(${e.target.value})`)}
          />
          <TextField
            label="Token Id"
            className="space"
            onChange={(e: any) => setTokenId(e.target.value)}
          />
        </Filter>
        <DataGrid
          disableColumnFilter
          className="dataGrid"
          rows={rows}
          columns={columns}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          page={page}
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
    </WithDrawer>
  );
};

export default TransferPage;
