import { ReactFC } from "../../scripts/types";
import moment from "moment";
import "./date-image.css";
import {
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ajax, urls, getImageUrl } from "../../scripts/api";
import { useSnackbar } from "notistack";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileUpload from "@mui/icons-material/FileUpload";

const DateImage: ReactFC<{
  onFileUpload: (filename: string) => void;
  file: string | null;
}> = ({ onFileUpload, file }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [filePath, setFilePath] = useState(file);

  const onChange = async (e: any) => {
    setLoading(true);
    const formdata = new FormData();

    formdata.append("pic", e.currentTarget.files[0]);

    const res = await ajax({
      method: "POST",
      data: formdata,
      hasToken: true,
      headers: { "Content-Type": "multipart/form-data" },
      snackbar: enqueueSnackbar,
      url: urls.upload,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    if (data.path) {
      onFileUpload(data.path);
      setFilePath(data.path);
    }
  };

  return (
    <div className="dateImageContainer">
      {filePath ? (
        <img className="image" src={getImageUrl(filePath)} />
      ) : (
        <div className="container">
          <div className="monthContainer">
            <Typography color="primary" mt={2} className="month">
              {moment().format("MMM").toUpperCase()}
            </Typography>
          </div>
          <div className="dayContainer">
            <Typography color="white" mt={2} className="day">
              {moment().format("DD")}
            </Typography>
          </div>
        </div>
      )}
      <div className="uploadContainer">
        <IconButton component="label" className="Button">
          <FileUpload color="primary" fontSize="large" />
          <input
            type="file"
            hidden
            onChange={onChange}
            accept="image/png, image/gif, image/jpeg"
          />
        </IconButton>
      </div>
    </div>
  );
};

export default DateImage;
