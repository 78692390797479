import React, { useEffect, useState } from "react";
import WithDrawer from "../../layouts/withDrawer";
import { ReactFC } from "../../scripts/types";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import "./profile.css";

type Profile = {
  createdAt: string;
  roles: string[];
  username: string;
};

const ProfilePage: ReactFC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState<Profile>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    getProfile();
  };

  const getProfile = async () => {
    setLoading(true);

    const res = await ajax({
      hasToken: true,
      method: "GET",
      url: urls.profile,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    setProfile(data.profile);
  };

  return (
    <WithDrawer title="Profile" showDrawer={false}>
      <div className="profilePageContainer">
        <Container className="profileContainer">
          {loading ? (
            <CircularProgress className="profileContainer__loading" />
          ) : (
            <div className="profile">
              <div className="profile__row">
                <span>username:</span>
                <span>{profile?.username}</span>
              </div>
              <div className="profile__row">
                <span>roles:</span>
                <span>{profile?.roles.join(" - ")}</span>
              </div>
              <div className="profile__row">
                <span>creation date:</span>
                <span>{moment(profile?.createdAt).format("YYYY/MM/DD")}</span>
              </div>
            </div>
          )}
        </Container>
      </div>
    </WithDrawer>
  );
};

export default ProfilePage;
