import React, { useEffect, useState } from "react";
import { ReactFC } from "../../scripts/types";
import WithDrawer from "../../layouts/withDrawer";
import { Button, TextField, Box, Container } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import "./config.css";

const ConfigPage: ReactFC = () => {
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [outbidPercent, setOutbidPercent] = useState("");
  const [auctionDuration, setAuctionDuration] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    setLoading(true);

    const res = await ajax({
      hasToken: true,
      url: urls.config,
      snackbar: enqueueSnackbar,
    });

    setLoading(false);

    if (!res) return;

    const { data } = res;

    setMinPrice(data.minPrice);
    setOutbidPercent(data.outbidPercent);
    setAuctionDuration(data.auctionDuration);
  };

  const udpateConfig = async () => {
    setSubmitLoading(true);

    const res = await ajax({
      hasToken: true,
      url: urls.config,
      method: "PATCH",
      data: {
        minPrice,
        outbidPercent: parseInt(outbidPercent),
        auctionDuration: parseInt(auctionDuration),
      },
      snackbar: enqueueSnackbar,
    });

    if (!res) return;

    const { data } = res;

    setShowSaveBtn(false);
    setSubmitLoading(false);
  };

  return (
    <WithDrawer title="Config" activeIndex={7}>
      <Container className="configRoot">
        <div className="configPageContainer">
          {loading ? (
            <CircularProgress sx={{ margin: "auto", height: "100%" }} />
          ) : (
            <div className="configPage">
              <TextField
                label="Minimum Price"
                className="TextField"
                type="number"
                onChange={(e: any) => setMinPrice(e.target.value)}
                value={minPrice}
                disabled={!showSaveBtn}
              />
              <TextField
                label="Outbid Percentage"
                className="TextField"
                type="number"
                onChange={(e: any) => setOutbidPercent(e.target.value)}
                value={outbidPercent}
                disabled={!showSaveBtn}
              />
              <TextField
                label="Auction Duration"
                className="TextField"
                type="number"
                onChange={(e: any) => setAuctionDuration(e.target.value)}
                value={auctionDuration}
                disabled={!showSaveBtn}
              />
              {showSaveBtn ? (
                <div className="configActionContainer">
                  <LoadingButton
                    loading={submitLoading}
                    variant="contained"
                    onClick={() => udpateConfig()}
                    className="Button"
                  >
                    Save
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={() => setShowSaveBtn(false)}
                    className="Button"
                  >
                    cancel
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setShowSaveBtn(true)}
                  className="Button"
                >
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
      </Container>
    </WithDrawer>
  );
};

export default ConfigPage;
