import React, { useEffect, useState } from "react";
import { ReactFC } from "../../scripts/types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import "./with-drawer.css";
import { List, ListItem, ListItemText } from "@mui/material";
import { Navigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const WithDrawer: ReactFC<{
  title?: string;
  activeIndex?: number;
  showDrawer?: boolean;
}> = ({ children, title, activeIndex = 0, showDrawer = true }) => {
  const pages = [
    "NFTs",
    "Packages",
    "Categories",
    "Transfers",
    "Sales",
    "Users",
    "FAQs",
    "Config",
  ];
  const navigatePages = [
    "nfts",
    "packages",
    "categories",
    "transfers",
    "sales",
    "users",
    "faqs",
    "config",
  ];
  const [auth, setAuth] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [toolbarText, setToolbarText] = useState(pages[0]);
  const [navigate, setNavigate] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) setAuth(true);
  }, []);

  const closeDrawer = () => setIsOpenDrawer(false);
  const openDrawer = () => setIsOpenDrawer(true);
  const onClickDrawerItem = (index: number) => {
    setNavigate(`/${navigatePages[index]}`);
  };

  const list = () => (
    <Box
      className="Box"
      role="presentation"
      onClick={() => closeDrawer()}
      onKeyDown={() => closeDrawer()}
    >
      <List>
        {pages.map((text, index) => (
          <ListItem button key={text} onClick={() => onClickDrawerItem(index)}>
            <ListItemText
              className={`ListItemText ${
                index === activeIndex ? "activeItem" : ""
              }`}
              primary={text}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const onLogout = () => {
    localStorage.removeItem("token");
    setNavigate("/login");
  };

  if (navigate) return <Navigate to={navigate} />;

  return (
    <div className="withDrawerContainer">
      <Box className="Box" sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {!showDrawer && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => window.history.back()}
                sx={{ mr: 2 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            {auth && title !== "Profile" && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setNavigate("/profile")}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <IconButton
                  size="large"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={onLogout}
                  color="inherit"
                >
                  <LogoutIcon />
                </IconButton>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <div className="container">
        {showDrawer && list()}
        <div className="children">{children}</div>
      </div>
    </div>
  );
};

export default WithDrawer;
