import { Button, TextField } from "@mui/material";
import { ReactFC } from "../../scripts/types";
import "./filter.css";

const Filter: ReactFC<{ onFilter: () => void }> = ({ children, onFilter }) => {
  return (
    <div className="filterContainer">
      {children}
      <Button onClick={onFilter} variant="contained" className="Button space">
        Filter
      </Button>
    </div>
  );
};

export default Filter;
