import { Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import WithDrawer from "../../layouts/withDrawer";
import { ajax, urls } from "../../scripts/api";
import { ReactFC } from "../../scripts/types";
import "./category.css";
import CategoryModal from "../../modals/category";
import QueryFilter from "../../components/queryFilter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../modals/confirm";

type Category = {
  id: string;
  icon: string;
  name: string;
};

const CategoryPage: ReactFC = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<Category[]>([]);
  const categoryModal = useRef<any>();
  const [query, setQuery] = useState("");
  const confirmModal = useRef<any>();
  const [id, setId] = useState("");

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 200, sortable: false },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      flex: 5,
      align: "center",
      renderCell: (params) => {
        const onClick = (e: any) => {
          categoryModal.current.openModal("edit", params.id, params.row);
        };

        return (
          <IconButton onClick={onClick}>
            <EditIcon color="primary" />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 5,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          if (params.row.id) {
            setId(params.row.id);
            confirmModal.current.openModal();
          }
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const deleteCategories = async (id: string) => {
    setLoading(true);
    const res = await ajax({
      method: "DELETE",
      url: urls.singleCategory(id),
      hasToken: true,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    setRows(rows.filter((item) => item.id !== id));
  };

  const componentDidMount = () => {
    getCategories();
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const getCategories = async (page = 1, size = 25) => {
    setLoading(true);

    const params: { pageSize: number; pageNumber: number; q?: string } = {
      pageSize: size,
      pageNumber: page,
    };

    if (query) params.q = query;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.categories,
      params,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    setRows(data.map((item: any) => ({ ...item, id: item._id })));

    setTotal(data.length ?? 0);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    getCategories(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);
    getCategories(page, size);
  };

  const openAddModal = (mode: "add" | "edit") => {
    categoryModal.current.openModal(mode);
  };

  return (
    <WithDrawer title="Categories" activeIndex={2}>
      <div className="categoriesContainer">
        <QueryFilter
          onChange={(value) => setQuery(value)}
          onFilter={() => getCategories()}
        />
        <Button
          onClick={() => openAddModal("add")}
          variant="contained"
          className="categoriesContainer__btn"
        >
          Add
        </Button>
        <DataGrid
          disableColumnFilter
          rows={rows}
          columns={columns}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          page={page}
          rowCount={total}
          className="dataGrid"
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
      <CategoryModal enqueueSnackbar={enqueueSnackbar} ref={categoryModal} />
      <ConfirmModal ref={confirmModal} action={() => deleteCategories(id)} />
    </WithDrawer>
  );
};

export default CategoryPage;
