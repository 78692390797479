import * as React from "react";
import BaseModal from "../base";
import "./category.css";
import { Button, TextField } from "@mui/material";
import { ajax, getImageUrl, urls } from "../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

class CategoryModal extends React.Component<
  {
    enqueueSnackbar?: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  {
    open: boolean;
    id: string;
    name: string;
    icon: string;
    mode: string;
    data: any;
    loading: boolean;
  }
> {
  state = {
    open: false,
    id: "",
    name: "",
    icon: "",
    mode: "add",
    data: { name: "", icon: "" },
    loading: false,
  };

  fillData = (data: any) =>
    this.setState({
      name: data?.name,
      icon: data?.icon,
    });

  openModal = (mode: "add" | "edit", id = "", data?: any) => {
    this.setState({ open: true, id, mode, data });

    if (mode === "edit") this.fillData(data);
  };

  closeModal = () => this.setState({ open: false, icon: "", name: "" });

  submit = async () => {
    const body = {
      name: this.state.name,
      icon: this.state.icon,
    };

    const res = await ajax({
      method: this.state.mode === "add" ? "POST" : "PATCH",
      url:
        this.state.mode === "add"
          ? urls.createCategory
          : urls.singleCategory(this.state.id),
      snackbar: this.props.enqueueSnackbar,
      hasToken: true,
      data: body,
    });

    if (!res) return;

    const { data } = res;

    this.closeModal();
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="categoryModal">
          <TextField
            label="Name"
            className="TextField"
            value={this.state.name}
            onChange={(e: any) => this.setState({ name: e.target.value })}
          />
          <Button
            disabled={this.state.loading}
            variant="contained"
            className="Button"
            onClick={this.submit}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default CategoryModal;
