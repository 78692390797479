import * as React from "react";
import BaseModal from "../../base";
import "./nft-edit.css";
import {
  Button,
  TextField,
  Stack,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ajax, getImageUrl, urls } from "../../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import AttributeModal from "../attribute";
import DateImage from "../../../components/dateImage";

type Attribute = { trait_type: string; value: string };

type Category = { _id: string; name: string };

type MetaData = {
  description: string;
  image: string;
  title: string;
};

type NFT = {
  id: string;
  _id: string;
  category: Category[];
  comments: number;
  date: string;
  favorited: boolean;
  favorites: number;
  liked: boolean;
  likes: number;
  package: string;
  packageItems: string[];
  sale: number;
  tokenId: string;
  metadata: MetaData;
};

type Props = {
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  categories: Category[];
};

type State = {
  open: boolean;
  id: string;
  loading: boolean;
  title: string;
  description: string;
  image: string;
  attributes: Attribute[];
  selectedCategories: Category[];
  selectValue: string;
  loadingUpload: boolean;
  filePath: string | null;
};

class EditNftModal extends React.Component<Props, State> {
  attributeModel: React.RefObject<AttributeModal>;
  constructor(props: Props) {
    super(props);

    this.attributeModel = React.createRef();
  }

  state = {
    open: false,
    id: "",
    loading: false,
    title: "",
    description: "",
    image: "",
    attributes: [],
    selectedCategories: [],
    selectValue: "",
    loadingUpload: false,
    filePath: "",
  };

  openModal = async (data: NFT) =>
    this.setState({
      id: data._id,
      open: true,
      title: data.metadata?.title,
      description: data.metadata?.description,
      filePath: data.metadata?.image,
      selectedCategories: data.category,
    });

  closeModal = () => this.setState({ open: false });

  submit = async () => {
    const body = {
      image: this.state.filePath,
      title: this.state.title,
      description: this.state.description,
      category: this.state.selectedCategories.map((item: Category) => item._id),
    };

    const res = await ajax({
      method: "POST",
      hasToken: true,
      data: body,
      url: urls.singleNft(this.state.id),
      snackbar: this.props.enqueueSnackbar,
    });

    if (!res) return;

    this.closeModal();
  };

  addAttributes = () => {
    this.attributeModel.current?.openModal();
  };

  handleDelete = (index: number) => {
    this.setState({
      selectedCategories: this.state.selectedCategories.filter(
        (item: Category, i: number) => i !== index
      ),
    });
  };

  handleChange = (e: SelectChangeEvent) => {
    const category = this.props.categories[parseInt(e.target.value)];

    const cats: any[] = this.state.selectedCategories;

    if (!cats.includes(category)) {
      this.setState({
        selectedCategories: [...this.state.selectedCategories, category],
      });
    }
  };

  onFileUpload = (filename: string): void => {
    this.setState({ filePath: filename });
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="nftEditModal">
          <div className="nftImage">
            <DateImage
              onFileUpload={this.onFileUpload}
              file={this.state.filePath}
            />
          </div>
          <div className="nftInfo">
            <TextField
              label="Title"
              className="TextField"
              value={this.state.title}
              onChange={(e: any) => this.setState({ title: e.target.value })}
            />
            <TextField
              multiline
              rows={3}
              label="Description"
              className="TextField"
              value={this.state.description}
              onChange={(e: any) =>
                this.setState({ description: e.target.value })
              }
            />
            <FormControl className="TextField">
              <InputLabel id="demo-simple-select-label">Categories</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.selectValue}
                label="categories"
                onChange={this.handleChange}
              >
                {this.props.categories.map((cat: Category, index: number) => (
                  <MenuItem key={cat._id} value={index}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" spacing={1} className="Stack">
              {this.state.selectedCategories.map(
                (cat: Category, index: number) => (
                  <Chip
                    key={cat._id}
                    label={cat.name}
                    onDelete={() => this.handleDelete(index)}
                  />
                )
              )}
            </Stack>
            <Button
              disabled={this.state.loading}
              variant="contained"
              className="Button"
              onClick={this.submit}
            >
              Submit
            </Button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

export default EditNftModal;
