import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export const urls = {
    login: '/admins/login',
    profile: '/admins/profile',
    config: '/configs',
    FAQs: '/faqs',
    FAQ: (id: string) => `/faqs/${id}`,
    sales: `/marketplace/sales`,
    cancelSale: (id: string) => `/marketplace/sales/${id}/cancel`,
    transfers: `/marketplace/transfers`,
    singleTransfers: (id: string) => `/marketplace/transfers/${id}`,
    users: `/users`,
    singleUser: (id: string) =>  `/users/${id}`,
    categories: `/marketplace/categories`,
    createCategory: `/marketplace/categories`,
    singleCategory: (id: string) => `/marketplace/categories/${id}`,
    packages: '/marketplace/packages',
    singlePackages: (id: string) => `/marketplace/packages/${id}`,
    nfts: '/marketplace/nfts',
    singleNft: (id: string) => `/marketplace/nfts/${id}`,
    singleNftPackage: (id: string) => `/marketplace/nfts/${id}/package`,
    upload: '/static-server/pics',
    nftPackage: (id: string) =>  `/marketplace/nfts/${id}/package`,
    nftCategory: (id: string) => `/marketplace/nfts/${id}/category`
}

export const getImageUrl = (path: string) =>
    `https://api.nft-history.wolfelabs.tech/api/v1/static-server/${path}`

export const ajax = async ({
    method = 'GET',
    headers = {},
    hasToken = false,
    token,
    url,
    data,
    snackbar,
    params = {}
}: {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    headers?: AxiosRequestHeaders | undefined;
    hasToken?: boolean;
    token?: string;
    url: string;
    data?: any;
    params?: any;
    snackbar?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
}): Promise<any> => {
    const headersObj: AxiosRequestHeaders = { ...headers }
    if (hasToken) headersObj.Authorization = `Bearer ${window.localStorage.getItem('token')}`
    if (token) headersObj.Authorization = `Bearer ${token}`

    const axiosObject: AxiosRequestConfig<any> = {
        url,
        method,
        params,
        headers: headersObj,
        baseURL: 'https://api.nft-history.wolfelabs.tech/api/v1'
    }

    if (data) axiosObject.data = data

    const res = await axios(axiosObject).catch((e: AxiosError) => {
        console.log(e)
        const msg = e.response?.data?.message || 'Something went wrong!!!'

        if (snackbar) snackbar(msg, { variant: 'error' })
    })

    if (!res) return

    if (res.data && !res.data.success && res.data.message && snackbar) snackbar(res.data.message, { variant: 'error' })

    return res.data
};
