import React, { useEffect, useRef, useState } from "react";
import { ReactFC } from "../../scripts/types";
import WithDrawer from "../../layouts/withDrawer";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import CancelSaleModal from "../../modals/sale/cancel";
import { ajax, urls } from "../../scripts/api";
import { useSnackbar } from "notistack";
import Filter from "../../components/filter";
import "./sales.css";
import CancelIcon from "@mui/icons-material/Cancel";

type Sale = {
  id: string;
  type: "auction" | "fixed-price" | "offer";
  status:
    | "pending"
    | "live"
    | "canceled"
    | "canceled-by-admin"
    | "completed"
    | "accepted"
    | "claimed";
  tokenId: string;
  seller: string;
  minPrice: number;
  startDate: string;
  endDate: string;
  buyer: string;
  value: string;
  bids: number;
  cancelationReason: string;
  isPackage: boolean;
  acceptedDate: string;
};

const SalePage: ReactFC<{}> = () => {
  const cancelSaleModal = useRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [rows, setRows] = useState<Sale[]>([]);
  const [total, setTotal] = useState(5);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    getSales(page, size);
  };

  const getSales = async (page = 1, size = 10) => {
    setLoading(true);

    const params: {
      pageSize: number;
      pageNumber: number;
      status?: string;
      type?: string;
    } = { pageSize: size, pageNumber: page };

    if (status) params.status = status;
    if (type) params.type = type;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.sales,
      params,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        bids: item.bids.length,
        tokenId: item?.tokenIdsUint?.join(",") ?? "",
        buyer: item?.buy?.maker,
        value: item?.buy?.makeAsset?.value
          ? parseInt(item?.buy?.makeAsset?.value).toString(16)
          : "",
      }))
    );
    setTotal(metaData?.pagination?.totalRecords || 0);
  };

  const onPageChange = (page = 10) => {
    setPage(page);

    getSales(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);

    getSales(page, size);
  };

  const columns: GridColDef[] = [
    { field: "type", headerName: "Type", flex: 50, sortable: false },
    { field: "status", headerName: "Status", flex: 100, sortable: false },
    { field: "tokenId", headerName: "Token Id(s)", flex: 100, sortable: false },
    { field: "seller", headerName: "Seller", flex: 100, sortable: false },
    {
      field: "minPrice",
      headerName: "Minimum Price",
      flex: 100,
      sortable: false,
    },
    { field: "startDate", headerName: "Start", flex: 100, sortable: false },
    { field: "endDate", headerName: "End", flex: 100, sortable: false },
    { field: "buyer", headerName: "Buyer", flex: 100, sortable: false },
    { field: "value", headerName: "Value", flex: 100, sortable: false },
    {
      field: "bids",
      headerName: "Bids",
      flex: 10,
      align: "center",
      sortable: false,
    },
    {
      field: "cancelationReason",
      headerName: "Cancelation Reason",
      flex: 100,
      sortable: false,
    },
    {
      field: "isPackage",
      headerName: "Is Package",
      flex: 100,
      sortable: false,
    },
    {
      field: "acceptedDate",
      headerName: "Accepted Date",
      flex: 100,
      sortable: false,
    },
    {
      field: "cancel",
      headerName: "Cancel",
      flex: 5,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          cancelSaleModal.current.openModal(params.id);
        };

        return (
          <IconButton disabled={params.row.status !== "live"} onClick={onClick}>
            <CancelIcon
              color={params.row.status !== "live" ? "disabled" : "primary"}
            />
          </IconButton>
        );
      },
    },
  ];

  return (
    <WithDrawer title="Sales" activeIndex={4}>
      <div className="salesPageContainer">
        <Filter onFilter={() => getSales(1, 25)}>
          <FormControl sx={{ width: 200 }} className="space">
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="status"
              onChange={(e: SelectChangeEvent) => setStatus(e.target.value)}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="live">live</MenuItem>
              <MenuItem value="canceled">canceled</MenuItem>
              <MenuItem value="canceled-by-admin">canceled by admin</MenuItem>
              <MenuItem value="completed">completed</MenuItem>
              <MenuItem value="claimed">claimed</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: 200 }} className="space">
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="status"
              onChange={(e: SelectChangeEvent) => setType(e.target.value)}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="auction">auction</MenuItem>
              <MenuItem value="fixed-price">fixed price</MenuItem>
            </Select>
          </FormControl>
        </Filter>
        <DataGrid
          rows={rows}
          columns={columns}
          className="dataGrid"
          disableColumnFilter
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          rowsPerPageOptions={[25, 50, 100]}
          page={page}
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
      <CancelSaleModal
        ref={cancelSaleModal}
        enqueueSnackbar={enqueueSnackbar}
      />
    </WithDrawer>
  );
};

export default SalePage;
