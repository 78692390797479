import * as React from "react";
import BaseModal from "../../base";
import "./category.css";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { ajax, getImageUrl, urls } from "../../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

type Category = {
  _id: string;
  name: string;
  icon?: string;
};

class CategoryModal extends React.Component<
  {
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  {
    open: boolean;
    id: string;
    loading: boolean;
    autoCompleteOpen: boolean;
    options: Category[];
    selectedOptions: Category[];
    defaultData: Category[];
  }
> {
  state = {
    open: false,
    id: "",
    loading: false,
    autoCompleteOpen: false,
    options: [],
    defaultData: [],
    selectedOptions: [],
  };

  openModal = async (id = "") => {
    this.setState({ open: true, id });

    this.setState({ loading: true });

    this.getNft(id)
      .then(() => {
        this.getPackages().finally(() => this.setState({ loading: false }));
      })
      .finally(() => this.setState({ loading: true }));
  };

  getNft = async (id: string) => {
    const res = await ajax({
      method: "GET",
      url: urls.singleNft(id),
      hasToken: true,
      snackbar: this.props.enqueueSnackbar,
    });

    if (!res) return;

    const { data } = res;

    this.setState({
      defaultData: data.category,
    });
  };

  getPackages = async (page = 1, size = 100) => {
    const res = await ajax({
      method: "GET",
      url: urls.categories,
      params: { pageNumber: page, pageSize: size },
      hasToken: true,
      snackbar: this.props.enqueueSnackbar,
    }).finally(() => this.setState({ loading: false }));

    if (!res) return;

    const { data, metaData } = res;

    this.setState({ options: data });
  };

  closeModal = () => this.setState({ open: false, options: [] });

  submit = async () => {
    if (!this.state.selectedOptions)
      return this.props.enqueueSnackbar("please select package", {
        variant: "error",
      });

    const selectedCategories: Category[] = this.state.selectedOptions;

    const body = {
      category: selectedCategories.length !== 0 && selectedCategories[0]._id,
    };

    const res = await ajax({
      method: "POST",
      url: urls.nftCategory(this.state.id),
      snackbar: this.props.enqueueSnackbar,
      hasToken: true,
      data: body,
    });

    if (!res) return;

    const { data } = res;

    this.closeModal();
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="categoryModal">
          <Autocomplete
            multiple
            id="tags-outlined"
            options={this.state.options}
            getOptionLabel={(option: Category) => option.name}
            defaultValue={this.state.defaultData}
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) =>
              this.setState({ selectedOptions: value })
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="categories"
                placeholder="select categories"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Button
            disabled={this.state.loading}
            variant="contained"
            className="Button"
            onClick={this.submit}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default CategoryModal;
