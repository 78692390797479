import React, { useEffect, useRef, useState } from "react";
import WithDrawer from "../../layouts/withDrawer";
import { ReactFC } from "../../scripts/types";
import { useSnackbar } from "notistack";
import { ajax, getImageUrl, urls } from "../../scripts/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import "./package.css";
import PackageModal from "../../modals/package";
import QueryFilter from "../../components/queryFilter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../modals/confirm";

type Package = {
  id: string;
  name: string;
  image: string;
  description: string;
  createdAt: string;
};

const PackagePage: ReactFC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<Package[]>([]);
  const packageModal = useRef<any>();
  const [query, setQuery] = useState("");
  const confirmModal = useRef<any>();
  const [id, setId] = useState("");

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 50, sortable: false },
    {
      field: "description",
      headerName: "Description",
      flex: 200,
      sortable: false,
    },
    { field: "createdAt", headerName: "Created At", flex: 15, sortable: false },
    {
      field: "image",
      headerName: "Image",
      flex: 20,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          params.row.image && (
            <img
              style={{
                objectFit: "cover",
                width: "50px",
                height: "50px",
              }}
              src={getImageUrl(params.row.image)}
            />
          )
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 5,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          openAddModal("edit", params.row.id, params.row);
        };

        return (
          <IconButton onClick={onClick}>
            <EditIcon color="primary" />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 5,
      align: "center",
      renderCell: (params) => {
        const onClick = (e: any) => {
          if (params.row.id) {
            setId(params.row.id);
            confirmModal.current.openModal();
          }
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const deletePackage = async (id: string) => {
    setLoading(true);
    const res = await ajax({
      method: "DELETE",
      hasToken: true,
      url: urls.singlePackages(id),
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    setRows(rows.filter((item) => item.id !== id));
  };

  const componentDidMount = async () => {
    getPackages(page, size);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const getPackages = async (page = 1, size = 25) => {
    setLoading(true);

    const params: { pageNumber: number; pageSize: number; q?: string } = {
      pageNumber: page,
      pageSize: size,
    };

    if (query) params.q = query;

    const res = await ajax({
      method: "GET",
      url: urls.packages,
      params,
      hasToken: true,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
      }))
    );

    setTotal(metaData?.pagination?.totalRecords);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    getPackages(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);
    getPackages(page, size);
  };

  const openAddModal = (mode: "add" | "edit", id = "", data?: any) => {
    packageModal.current.openModal(id, mode, data);
  };

  return (
    <WithDrawer title="Packages" activeIndex={1}>
      <div className="packagesContainer">
        <QueryFilter
          onChange={(value) => setQuery(value)}
          onFilter={() => getPackages()}
        />
        <Button
          onClick={() => openAddModal("add")}
          variant="contained"
          className="packagesContainer__btn"
        >
          Add
        </Button>
        <DataGrid
          rows={rows}
          disableColumnFilter
          className="dataGrid"
          columns={columns}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          page={page}
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
      <PackageModal enqueueSnackbar={enqueueSnackbar} ref={packageModal} />
      <ConfirmModal ref={confirmModal} action={() => deletePackage(id)} />
    </WithDrawer>
  );
};

export default PackagePage;
