import * as React from "react";
import BaseModal from "../base";
import "./package.css";
import {
  Button,
  TextField,
  Stack,
  Chip,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ajax, getImageUrl, urls } from "../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import moment from "moment";
import SearchNFTModal from "../../modals/searchNft";

type MetaData = {
  description: string;
  image: string;
  title: string;
};

type Category = { _id: string; name: string };

type NFT = {
  id: string;
  _id: string;
  category: Category[];
  comments: number;
  date: string;
  favorited: boolean;
  favorites: number;
  liked: boolean;
  likes: number;
  package: string;
  packageItems: NFT[];
  sale: number;
  tokenId: string;
  metadata: MetaData;
};

type State = {
  open: boolean;
  id: string;
  filePath: string;
  name: string;
  description: string;
  loadingUpload: boolean;
  mode: string;
  price: number | undefined;
  data: any;
  nfts: NFT[];
  loading: boolean;
  selectedNfts: NFT[];
  nftLoading: boolean;
  selectedNftLoading: boolean;
};

type Package = {
  _id: string;
  date: string;
};

type Props = {
  enqueueSnackbar?: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
};

class PackageModal extends React.Component<Props, State> {
  searchNFTModal: React.RefObject<SearchNFTModal>;

  constructor(props: Props) {
    super(props);

    this.searchNFTModal = React.createRef();

    this.state = {
      open: false,
      id: "",
      filePath: "",
      name: "",
      description: "",
      loadingUpload: false,
      price: 0,
      loading: false,
      mode: "add",
      data: { name: "", description: "", image: "" },
      selectedNfts: [],
      nfts: [],
      nftLoading: false,
      selectedNftLoading: false,
    };
  }

  fillData = (data: any) =>
    this.setState({
      name: data?.name,
      description: data?.description,
      filePath: data?.image,
    });

  openModal = async (id = "", mode: "add" | "edit", data?: any) => {
    this.setState({ open: true, id, mode, data });

    this.getNfts(id);

    if (mode === "edit") this.fillData(data);

    if (!id) return;

    this.setState({ nftLoading: true, selectedNftLoading: true });

    const [selectedNfts = []] = await Promise.all([this.getNfts(id)]).finally(
      () => this.setState({ nftLoading: false, selectedNftLoading: false })
    );

    this.setState({
      selectedNfts:
        selectedNfts?.length === 0 ? [] : selectedNfts[0].packageItems,
    });
  };

  closeModal = () =>
    this.setState({
      open: false,
      filePath: "",
      name: "",
      id: "",
      description: "",
      selectedNfts: [],
      nfts: [],
    });

  onChangeFile = async (e: any) => {
    this.setState({ loadingUpload: true });
    const formdata = new FormData();

    formdata.append("pic", e.currentTarget.files[0]);

    const res = await ajax({
      method: "POST",
      data: formdata,
      hasToken: true,
      headers: { "Content-Type": "multipart/form-data" },
      snackbar: this.props.enqueueSnackbar,
      url: urls.upload,
    }).finally(() => this.setState({ loadingUpload: false }));

    if (!res) return;

    const { data } = res;

    this.setState({ filePath: data?.path });
  };

  submit = async () => {
    this.setState({ loading: true });
    const body = {
      name: this.state.name,
      description: this.state.description,
      image: this.state.filePath,
    };

    const res = await ajax({
      method: !this.state.id ? "POST" : "PATCH",
      url: !this.state.id ? urls.packages : urls.singlePackages(this.state.id),
      snackbar: this.props.enqueueSnackbar,
      hasToken: true,
      data: body,
    }).finally(() => this.setState({ loading: false }));

    if (!res) return;

    this.closeModal();
  };

  onAddNFT = (data: NFT) => {
    this.setState({ selectedNfts: [...this.state.selectedNfts, data] });
  };

  getNfts = async (id?: string, tokenId = ""): Promise<NFT[] | undefined> => {
    const params: {
      pageNumber: number;
      pageSize: number;
      tokenId?: string;
      package?: string;
    } = {
      pageNumber: 1,
      pageSize: 30,
    };

    if (tokenId) params.tokenId = tokenId;
    if (id) params.package = id;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.nfts,
      snackbar: this.props.enqueueSnackbar,
      params: params,
    });

    if (!res) return;

    const { data } = res;

    return data;
  };

  searchNft = async (tokenId: string) => {
    this.setState({ nftLoading: true });

    const data = await this.getNfts("", tokenId).finally(() =>
      this.setState({ nftLoading: false })
    );

    this.setState({ nfts: data ?? [] });
  };

  onDeleteNFT = async (nftId: string) => {
    const res = await ajax({
      method: "DELETE",
      url: urls.singleNftPackage(nftId),
      snackbar: this.props.enqueueSnackbar,
      hasToken: true,
    });

    if (!res) return;

    const { data } = res;

    this.setState({
      selectedNfts: this.state.selectedNfts.filter(
        (nft: NFT) => nft._id !== nftId
      ),
    });
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="packageModal">
          <TextField
            label="Name"
            className="TextField"
            value={this.state.name}
            onChange={(e: any) => this.setState({ name: e.target.value })}
          />
          <TextField
            label="Description"
            multiline
            rows={5}
            className="TextField"
            value={this.state.description}
            onChange={(e: any) =>
              this.setState({ description: e.target.value })
            }
          />
          <Button variant="contained" component="label" className="Button">
            Upload File
            <input
              type="file"
              hidden
              onChange={this.onChangeFile}
              accept="image/png, image/gif, image/jpeg"
            />
          </Button>
          {this.state.filePath && (
            <img
              src={getImageUrl(this.state.filePath)}
              className="packageModal__img"
            />
          )}
          <Button
            onClick={() =>
              this.searchNFTModal.current?.openModal(this.state.id)
            }
            variant="contained"
            className="Button"
            color="primary"
          >
            Add NFT
          </Button>
          {this.state.id && this.state.selectedNftLoading ? (
            <CircularProgress className="loading" />
          ) : (
            <Stack direction={"row"} className="Stack">
              {this.state.selectedNfts.map((nft: NFT) => (
                <Chip
                  className="Chip"
                  key={nft._id}
                  onDelete={() => this.onDeleteNFT(nft._id)}
                  label={moment(nft.date).format("YYYY/MM/DD")}
                />
              ))}
            </Stack>
          )}
          <LoadingButton
            onClick={this.submit}
            loading={this.state.loading}
            variant="contained"
            className="Button"
            color="primary"
            disabled={this.state.loadingUpload}
          >
            Submit
          </LoadingButton>
        </div>
        <SearchNFTModal
          ref={this.searchNFTModal}
          enqueueSnackbar={this.props.enqueueSnackbar}
          onSubmit={this.onAddNFT}
        />
      </BaseModal>
    );
  }
}

export default PackageModal;
