import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import "./confirm.css";

type State = { open: boolean };

type Props = { action?: () => any };

class ConfirmModal extends React.Component<Props, State> {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  handleAction = () => {
    if (this.props.action) this.props.action();
    this.handleClose();
  };

  render(): React.ReactNode {
    return (
      <div className="confirmModal">
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="DialogTitle">
            Alert
          </DialogTitle>
          <DialogContent className="DialogContent">
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete item?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="DialogActions">
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleAction} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmModal;
