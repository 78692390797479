import * as React from "react";
import { ReactFC } from "../../../scripts/types";
import BaseModal from "../../base";
import TextField from "@mui/material/TextField";
import "./cancel.css";
import { Button } from "@mui/material";
import { ajax, urls } from "../../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

class CancelSaleModal extends React.Component<
  {
    enqueueSnackbar?: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  { open: boolean; id: string; reason: string }
> {
  state = {
    open: false,
    id: "",
    reason: "",
  };

  openModal = (id: string) => this.setState({ open: true, id });
  closeModal = () => this.setState({ open: false });

  cancelSale = async () => {
    const res = await ajax({
      url: urls.cancelSale(this.state.id),
      method: "POST",
      data: {
        cancelationReason: this.state.reason,
      },
      hasToken: true,
      snackbar: this.props.enqueueSnackbar,
    });

    if (!res) return;

    const { data } = res;

    this.closeModal();
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="cancelSaleContant">
          <TextField
            label="reason"
            multiline
            rows={5}
            className="TextField"
            onChange={(e: any) => this.setState({ reason: e.target.value })}
          />
          <Button
            variant="contained"
            className="Button"
            onClick={this.cancelSale}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default CancelSaleModal;
