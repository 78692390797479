import React, { useEffect, useState } from "react";
import "./user-transactions.css";
import WithDrawer from "../../layouts/withDrawer";
import { ReactFC } from "../../scripts/types";
import { useSnackbar } from "notistack";
import { ajax, urls } from "../../scripts/api";
import moment from "moment";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import "./user-transactions.css";

type Transfer = {
  id: string;
  blockNumber: number;
  createdAt: string;
  wallet: string;
  name: string;
  tokenId: number;
  transactionHash: string;
};

const UserTransactionsPage: ReactFC<{}> = ({}) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<Transfer[]>([]);
  const [wallet, setWallet] = useState("");
  const { state } = useLocation();

  const columns: GridColDef[] = [
    {
      field: "blockNumber",
      headerName: "Block Number",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "wallet",
      headerName: "Wallet",
      width: 50,
      flex: 50,
      sortable: false,
    },
    { field: "name", headerName: "Name", width: 50, flex: 50, sortable: false },
    {
      field: "tokenId",
      headerName: "Token ID",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "transactionHash",
      sortable: false,
      headerName: "Transaction Hash",
      width: 50,
      flex: 50,
    },
  ];

  const componentDidMount = async () => {
    getTransactions(page, size, state.wallet);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const getTransactions = async (page = 1, size = 25, wallet = "") => {
    setLoading(true);

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.transfers,
      params: {
        pageNumber: page,
        pageSize: size,
        wallet: wallet,
      },
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        wallet: item.to?.wallet,
        name: item.to?.name,
        createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
      }))
    );

    setTotal(metaData?.pagination?.totalRecords ?? 0);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    getTransactions(page, size, state.wallet);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);
    getTransactions(page, size, state.wallet);
  };

  return (
    <WithDrawer title="User Transactions" showDrawer={false}>
      <div className="userTransactionContainer">
        <DataGrid
          rows={rows}
          columns={columns}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          page={page}
          disableColumnFilter
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
    </WithDrawer>
  );
};

export default UserTransactionsPage;
