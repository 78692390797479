import * as React from "react";
import { ReactFC } from "../../scripts/types";
import BaseModal from "../base";
import TextField from "@mui/material/TextField";
import "./transactions.css";
import { Button } from "@mui/material";
import { ajax, urls } from "../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

class TransactionModal extends React.Component<
  {
    enqueueSnackbar?: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey;
  },
  { open: boolean; id: string }
> {
  state = {
    open: false,
    id: "",
  };

  componentDidUpdate() {
    if (!this.state.open) return;

    this.getTransactions();
  }

  async getTransactions() {
    const data = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.singleUser(this.state.id),
      snackbar: this.props.enqueueSnackbar,
    });

    console.log(data);
  }

  openModal = (id: string) => this.setState({ open: true, id });
  closeModal = () => this.setState({ open: false });

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div></div>
      </BaseModal>
    );
  }
}

export default TransactionModal;
