import * as React from "react";
import BaseModal from "../base";
import "./search-nft.css";
import {
  Autocomplete,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ajax, getImageUrl, urls } from "../../scripts/api";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker, LoadingButton } from "@mui/lab";
import moment from "moment";

type MetaData = {
  description: string;
  image: string;
  title: string;
};

type Category = { _id: string; name: string };

type NFT = {
  id: string;
  _id: string;
  category: Category[];
  comments: number;
  date: string;
  favorited: boolean;
  favorites: number;
  liked: boolean;
  likes: number;
  package: string;
  packageItems: NFT[];
  sale: number;
  tokenId: string;
  metadata: MetaData;
};

type Package = {
  _id: string;
  date: string;
};

type Props = {
  enqueueSnackbar?: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  onSubmit: (data: NFT) => void;
};

type State = {
  open: boolean;
  id: string;
  loading: boolean;
  nftLoading: boolean;
  nfts: NFT[];
  selectedNFT: NFT | null;
  date: string | null;
  openAutoComplete: boolean;
};

class CategoryModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      id: "",
      loading: false,
      nftLoading: false,
      nfts: [],
      selectedNFT: null,
      date: null,
      openAutoComplete: false,
    };
  }

  openModal = async (id: string) =>
    this.setState({
      open: true,
      id,
      selectedNFT: null,
      openAutoComplete: false,
      loading: false,
    });

  closeModal = () => this.setState({ open: false, selectedNFT: null });

  updateNft = async (id: string, packageId: string) => {
    const res = await ajax({
      method: "POST",
      hasToken: true,
      url: urls.singleNftPackage(id),
      data: { package: packageId },
      snackbar: this.props.enqueueSnackbar,
    });

    if (!res) return;

    const { data } = res;

    if (!res.success) throw res.success;

    return data;
  };

  submit = async () => {
    if (!this.state.selectedNFT && this.props.enqueueSnackbar)
      return this.props.enqueueSnackbar("Please select NFT", {
        variant: "error",
      });

    if (!this.state.selectedNFT) return;

    try {
      const res = await this.updateNft(
        this.state.selectedNFT._id,
        this.state.id
      );

      this.props.onSubmit(this.state.selectedNFT);
    } catch (e) {}

    this.closeModal();
  };

  getNfts = async (date?: string): Promise<NFT[] | undefined> => {
    const params: {
      pageNumber: number;
      pageSize: number;
      date?: string;
    } = {
      pageNumber: 1,
      pageSize: 30,
    };

    if (date) params.date = date;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      url: urls.nfts,
      snackbar: this.props.enqueueSnackbar,
      params: params,
    });

    if (!res) return;

    const { data } = res;

    return data;
  };

  searchNft = async (date?: string) => {
    this.setState({ nftLoading: true });

    const data = await this.getNfts(date).finally(() =>
      this.setState({ nftLoading: false })
    );

    if (!data || data?.length === 0) return;

    this.setState({ selectedNFT: data[0] });
  };

  render() {
    return (
      <BaseModal
        onClose={this.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <div className="searchNftModal">
          <div className="searchContainer space">
            <LocalizationProvider
              className="space"
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Basic example"
                className="DatePicker"
                value={this.state.date}
                onChange={(newValue) => {
                  if (newValue && moment(newValue).isValid())
                    this.setState({
                      date: new Date(
                        new Date(newValue).setUTCHours(0, 0, 0, 0)
                      ).toISOString(),
                    });
                  else this.setState({ date: null });
                }}
                renderInput={(params: any) => (
                  <TextField className="TextField" {...params} />
                )}
              />
            </LocalizationProvider>
            <LoadingButton
              loading={this.state.nftLoading}
              className="Button"
              variant="contained"
              onClick={() => this.searchNft(this.state.date ?? "")}
            >
              search
            </LoadingButton>
          </div>
          <TextField
            label="Token ID"
            className="TextField"
            value={this.state.selectedNFT ? this.state.selectedNFT.tokenId : ""}
            disabled
          />
          <Button
            disabled={this.state.loading}
            variant="contained"
            className="Button"
            onClick={this.submit}
          >
            Submit
          </Button>
        </div>
      </BaseModal>
    );
  }
}

export default CategoryModal;
