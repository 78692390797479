import { ReactFC } from "../../scripts/types";
import { Button, TextField } from "@mui/material";
import "./query-filter.css";

const QueryFilter: ReactFC<{
  onChange: (value: string) => void;
  onFilter: () => void;
}> = ({ onChange, onFilter, children }) => {
  return (
    <div className="queryFilterContainer">
      <TextField
        label="Query"
        onChange={(e: any) => onChange(e.target.value)}
      />
      {children}
      <Button onClick={onFilter} variant="contained" className="Button space">
        Filter
      </Button>
    </div>
  );
};

export default QueryFilter;
