import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { ReactFC } from "../../scripts/types";
import WithDrawer from "../../layouts/withDrawer";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Button, IconButton, Fab } from "@mui/material";
import "./faqs.css";
import AddFAQModal from "../../modals/FAQs/add";
import { ajax, urls } from "../../scripts/api";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../modals/confirm";
import AddIcon from "@mui/icons-material/Add";

type FAQ = {
  id: string;
  answer: string;
  question: string;
};

const FAQsPage: ReactFC<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<FAQ[]>([]);
  const addFaqModal = useRef<any>();
  const confirmModal = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    setLoading(true);
    const res = await ajax({
      hasToken: true,
      url: urls.FAQs,
      method: "GET",
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    if (!data) return;

    setRows([
      ...rows,
      ...data.map(
        (item: { _id: string; answer: string; question: string }) => ({
          ...item,
          id: item._id,
        })
      ),
    ]);
  };

  const deleteFAQ = async (id: any) => {
    setLoading(true);
    const res = await ajax({
      method: "DELETE",
      url: urls.FAQ(id),
      hasToken: true,
      snackbar: enqueueSnackbar,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data } = res;

    if (!data) return;

    setRows(rows.filter((item) => item.id !== id));
  };

  const columns: GridColDef[] = [
    {
      field: "question",
      headerName: "Question",
      width: 50,
      flex: 50,
      sortable: false,
    },
    {
      field: "answer",
      headerName: "Answer",
      width: 50,
      flex: 100,
      sortable: false,
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 5,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          if (params.id) {
            setId(params.row.id);
            confirmModal.current.openModal();
          }
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const openAddModal = () => {
    addFaqModal.current.openModal();
  };

  return (
    <WithDrawer title="FAQs" activeIndex={6}>
      <div className="faqPageContainer">
        {/* <Fab color="primary" aria-label="add" className="Fab">
          <AddIcon />
        </Fab> */}
        <Button
          onClick={openAddModal}
          variant="contained"
          className="faqPageContainer__btn"
        >
          Add
        </Button>
        <DataGrid
          disableColumnFilter
          rows={rows}
          columns={columns}
          loading={loading}
        />
      </div>
      <AddFAQModal ref={addFaqModal} enqueueSnackbar={enqueueSnackbar} />
      <ConfirmModal ref={confirmModal} action={() => deleteFAQ(id)} />
    </WithDrawer>
  );
};

export default FAQsPage;
