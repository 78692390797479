import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ac84df",
    },
    secondary: {
      main: "#40434c",
    },
    text: {
      primary: "#a4b1d7",
      secondary: "#a4b1d7",
      disabled: "#a4b1d7",
    },
  },
});

export default theme;
