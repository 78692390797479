import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  RouteProps,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";
import LoginPage from "./pages/login";
import NftsPage from "./pages/nfts";
import FAQsPage from "./pages/FAQs";
import SalePage from "./pages/sales";
import ConfigPage from "./pages/config";
import UserPage from "./pages/user";
import TransferPage from "./pages/transfer";
import CategoryPage from "./pages/category";
import PackagePage from "./pages/package";
import { useEffect, useState } from "react";
import { ReactFC } from "./scripts/types";
import ProfilePage from "./pages/profile";
import UserTransactionsPage from "./pages/userTransactions";

const PrivateRoute: ReactFC<RouteProps> = ({ path }) => {
  if (localStorage.getItem("token") && !path) return <Outlet />;
  else if ((path === "/login" || path === "/") && localStorage.getItem("token"))
    return <Navigate to="/nfts" />;
  else return <Navigate to="/login" />;
};

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute path="/" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/nfts" element={<PrivateRoute />}>
            <Route path="/nfts" element={<NftsPage />} />
          </Route>
          <Route path="/faqs" element={<PrivateRoute />}>
            <Route path="/faqs" element={<FAQsPage />} />
          </Route>
          <Route path="/sales" element={<PrivateRoute />}>
            <Route path="/sales" element={<SalePage />} />
          </Route>
          <Route path="/config" element={<PrivateRoute />}>
            <Route path="/config" element={<ConfigPage />} />
          </Route>
          <Route path="/users/transactions" element={<PrivateRoute />}>
            <Route
              path="/users/transactions"
              element={<UserTransactionsPage />}
            />
          </Route>
          <Route path="/users" element={<PrivateRoute />}>
            <Route path="/users" element={<UserPage />} />
          </Route>
          <Route path="/transfers" element={<PrivateRoute />}>
            <Route path="/transfers" element={<TransferPage />} />
          </Route>
          <Route path="/categories" element={<PrivateRoute />}>
            <Route path="/categories" element={<CategoryPage />} />
          </Route>
          <Route path="/packages" element={<PrivateRoute />}>
            <Route path="/packages" element={<PackagePage />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
