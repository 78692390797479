import React, { useEffect, useRef, useState } from "react";
import WithDrawer from "../../layouts/withDrawer";
import { ReactFC } from "../../scripts/types";
import { useSnackbar } from "notistack";
import { ajax, urls } from "../../scripts/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import TransactionModal from "../../modals/transactions";
import { Navigate } from "react-router-dom";
import QueryFilter from "../../components/queryFilter";
import "./user.css";
import ReceiptIcon from "@mui/icons-material/Receipt";

type User = {
  id: string;
  createdAt: string;
  followers: number;
  followings: number;
  wallet: string;
};

const UserPage: ReactFC = ({}) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<User[]>([]);
  const transactionModal = useRef<any>();
  const [navigate, setNavigate] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [query, setQuery] = useState("");
  const [sortName, setSortName] = useState("");

  const columns: GridColDef[] = [
    { field: "wallet", headerName: "Wallet", flex: 70, sortable: false },
    { field: "name", headerName: "Name", flex: 30, sortable: false },
    {
      field: "followers",
      headerName: "Followers",
      flex: 12,
      align: "center",
      sortable: false,
    },
    {
      field: "followings",
      headerName: "Followings",
      flex: 12,
      align: "center",
      sortable: false,
    },
    { field: "createdAt", headerName: "Created At", flex: 20, sortable: false },
    {
      field: "ownedNFTs",
      headerName: "Owned NFTs",
      flex: 15,
      align: "center",
      sortable: false,
    },
    {
      field: "action",
      headerName: "Transactions",
      flex: 10,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          setNavigate(`/users/transactions`);
          setSelectedWallet(params.row.wallet);
        };

        return (
          <IconButton onClick={onClick}>
            <ReceiptIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const componentDidMount = async () => {
    getUsers(page, size);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const getUsers = async (page = 1, size = 25) => {
    setLoading(true);

    const params: {
      pageSize: number;
      pageNumber: number;
      q?: string;
      sort?: string;
    } = {
      pageSize: size,
      pageNumber: page,
    };

    if (query) params.q = query;
    if (sortName) params.sort = sortName;

    const res = await ajax({
      method: "GET",
      hasToken: true,
      snackbar: enqueueSnackbar,
      url: urls.users,
      params,
    }).finally(() => setLoading(false));

    if (!res) return;

    const { data, metaData } = res;

    setRows(
      data.map((item: any) => ({
        ...item,
        id: item._id,
        createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
      }))
    );

    setTotal(metaData?.pagination?.totalRecords ?? 0);

    console.log(data);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    getUsers(page, size);
  };

  const onPageSizeChange = (size: number) => {
    setSize(size);
    getUsers(page, size);
  };

  if (navigate)
    return (
      <Navigate
        to={{ pathname: navigate }}
        state={{ wallet: selectedWallet }}
      />
    );

  return (
    <WithDrawer title="Users" activeIndex={5}>
      <div className="userPageContainer">
        <QueryFilter
          onChange={(value) => setQuery(value)}
          onFilter={() => getUsers()}
        >
          <FormControl className="space" sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Sort Name</InputLabel>
            <Select
              value={sortName}
              label="Sort Name"
              onChange={(e: SelectChangeEvent) => setSortName(e.target.value)}
            >
              <MenuItem value={""}>none</MenuItem>
              <MenuItem value={"+name"}>Ascending</MenuItem>
              <MenuItem value={"-name"}>Descending</MenuItem>
            </Select>
          </FormControl>
        </QueryFilter>
        <DataGrid
          className="dataGrid"
          rows={rows}
          columns={columns}
          disableColumnFilter
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pagination
          page={page}
          rowCount={total}
          paginationMode="server"
          pageSize={size}
          loading={loading}
        />
      </div>
      <TransactionModal
        enqueueSnackbar={enqueueSnackbar}
        ref={transactionModal}
      />
    </WithDrawer>
  );
};

export default UserPage;
